const tw = {
    ChatLayout: {
        nav_chat: "談話",
        resource: "談話資料管理",
        upload: "談話資料登録",
        history: "履歷",
        setting: "設定",
        connection: "外部連接",
        delete: "刪除",
        clear_messages: "清空",
        back: "返回",
    },
    NavBar: {
        chats: "談話一覽",
        admin: "用戶管理",
        settings: "設定",
        logout: "登出",
        saleslists: "銷售列表",
        salestemplate: "範本",
        usage: "費用明細",
    },
    EditGroupView: {
        h1: "用戶組編輯：",
        group_name: "用戶組名：",
        created_at: "創建日期和時間：",
        modified_at: "最終更新日期：",
        user_group_h4: "用戶組的用戶添加",
        username: "用戶名",
        fullname: "姓名",
        roles: "權限",
        user_group_h4_2: "可用的談話機器人添加",
        chat_title: "談話機器人名",
        submit: "更新",
        handleBack: "返回",
        edit_group_err: "用戶組信息變更失敗！",
    },
    EditUserView: {
        h1: "用戶編輯：",
        username: "用戶ID：",
        mail_address: "電子郵件地址：",
        password: "密碼：",
        show: "表示",
        not_show: "非表示",
        roles: "權限：",
        groups: "用戶組：",
        created_at: "用戶登錄日時：",
        modified_at: "最終更新日期：",
        submit: "更新",
        handleBack: "返回",
        change_password: "密碼変更",
        password_format_err: "密碼長度必須至少為 8 個字符，並包含大小寫字母、數字和特殊字符。",
        role_error: "請選擇用戶權限",
        group_error: "請選擇用戶所屬的用戶組",
    },
    NewGroupView: {
        h1: "新建用戶組",
        group_name: "用戶組名：",
        user_group_h4: "用戶組的用戶添加",
        username: "用戶名",
        fullname: "姓名",
        roles: "權限",
        user_group_h4_2: "用戶組談話機器人添加",
        chat_title: "談話機器人名",
        submit: "保存",
        handleBack: "返回",
        new_group_err: "新用戶組注冊失敗！\n請確認該用戶組是否已經被注冊。",
        registered_group: "※已注冊的用戶組，請更改用戶組名。",
    },
    NewUserView: {
        h1: "新建用戶",
        username: "用戶ID：",
        full_name: "全名：",
        mail_address: "電子郵件地址：",
        password: "密碼：",
        show: "表示",
        not_show: "非表示",
        roles: "權限：",
        groups: "用戶組：",
        submit: "注冊",
        handleBack: "返回",
        password_format_err: "密碼長度必須至少為 8 個字符，並包含大小寫字母、數字和特殊字符。",
        role_error: "請選擇用戶權限",
        group_error: "請選擇用戶所屬的用戶組",
    },
    UserListView: {
        user_h4: "用戶一覽",
        add_User: "新建用戶",
        username: "用戶名",
        fullname: "姓名",
        roles: "權限",
        groups: "用戶組",
        modified_at: "最終更新日期",
        created_at: "登錄日期",
        operation: "操作",
        edit: "編輯",
        delete: "刪除",
        group_h4: "用戶組一覽",
        add_group: "新建用戶組",
        group_name: "用戶組名",
        update_date_time: "更新日期",
        ip_h4: "IP白名單",
        ip_list: "IP",
        add_ip: "IP追加",
        modal_title: "警告",
        warning_msg_1: "刪除用戶組請注意以下事項:",
        warning_msg_2: "1.屬於該用戶組的用戶和談話將會取消與該用戶組關聯。",
        warning_msg_3: "2.如果某個談話只關聯了該用戶組，則只有擁有管理員權限的用戶才能看到或者編輯此談話",
        warning_msg_4: "要刪除嗎？",
        cancel: "取消",
        del_err: "用戶組刪除失敗！\n該用戶組不存在或已經刪除。\n請刷新畫面再試一次。",
        ip_del_err: "IP刪除失敗！",
        ip_edit_err: "請輸入正確的IP地址",
        ip_save_err: "IP保存失敗！",
        ip_edit_err2: "IP已經存在！",
        save: "保存",
    },
    AdminErrorView: {
        h1: "無訪問權限",
        error_message: "請向您的系統管理員請求訪問權限或切換到具有訪問權限的帳戶",
        back_button: "返回",
    },
    ChatResourceUploadView: {
        h1: "談話資料登録",
        resource_type: "談話資料類型",
        file: "文件",
        choose_file: "選擇文件",
        file_name: "文件名",
        tags: "標簽",
        tags_format: "標簽1,標簽2,標簽3",
        upload: "上傳",
        page_title: "頁面標題",
        page_name: "頁名",
        uploadWebResource: "登録",
        no_files_selected: "未選擇任何文本。",
        update_ok_msg: "上傳成功。",
        update_err_msg: "上傳失敗。",
        response_ok_msg: "數據錄入成功。",
        response_err_msg: "數據錄入失敗。",
        not_pdf: "上傳的文件必須爲PDF文件",
    },
    ChatResourceView: {
        h4: "已登錄談話資料一覽",
        keyword: "關鍵詞",
        tags: "標簽",
        total_number_of_characters: "文字數合計：",
        resource_count: "談話資料数：",
        filter: "過濾",
        resource_name: "談話資料名",
        file_type: "文件形式",
        created_at: "登錄日期",
        status: "狀態",
        operation: "操作",
        edit: "編輯",
        delete: "刪除",
        status_zero: "處理等待",
        status_one: "讀取中",
        status_two: "完成",
        status_three: "錯誤",
    },
    ChatScreenView: {
        input_msg: "請輸入信息",
        error_messages: "發生了錯誤，請稍後再試",
        close: "關閉",
        template_message: "範本問題",
        link: "外部鏈接",
        new_talk: "+ 新談話",
        his_talk: "談話記錄",
    },
    ChatSettingView: {
        h4: "談話機器人設定",
        chat_namespace: "談話ID：",
        chat_title: "談話機器人名：",
        system_prompt: "系統提示符：",
        temperature: "回答的靈活性：",
        model: "模型",
        template_message: "範本問題：",
        add: "追加",
        del: "刪除",
        title: "標題",
        msg: "提問内容",
        must: "必填",
        group: "可查看的用戶組",
        memory: "記憶談話",
        update: "更新",
        handle_return: "返回",
        alert_max_10: "提問内容最多只能記錄10條",
        alert_not_edit: "存在沒有記入的提問内容，請記入提問内容。",
        chat_setting_err_msg: "談話機器人設定失敗。",
        chat_setting_ok_msg: "談話機器人設定成功。",
    },
    ChatConnectionView: {
        h4: "外部系統連接",
        messenger: "Messenger",
        messenger_service_name: "連接目標服務：",
        messenger_active_status: "有效狀態：",
        messenger_callback_url: "回呼URL：",
        messenger_verify_token: "驗證令牌：",
        messenger_facebook_page_id: "Facebook頁面ID：",
        messenger_access_token: "訪問令牌：",
        submit: "更新",
        hide_token: "隱藏",
        show_token: "顯示",
        error_messages: "發生了錯誤",
        error_messages_fbduplicate: "無法更新，因為 Facebook 專頁 ID 重複",
    },

    NewChatView: {
        h3: "談話機器人定制",
        chat_title: "談話機器人名：",
        groups: "擁有瀏覽權限的用戶組：",
        system_prompt: "系統提示符：",
        temperature: "回答的靈活性：",
        group_err: "請選擇可查看的用戶組",
        memory: "記憶談話",
        handleCreate: "注冊",
        handleReturn: "返回",
    },
    EditPasswordView: {
        h1: "密碼変更",
        old_password: "原密碼：",
        incorrect_password: "密碼不正確！",
        new_password: "新密碼：",
        new_password_confirmation: "新密碼（確認）：",
        passwords_do_not_match: "密碼不一致！",
        password_format_err: "密碼長度必須至少為 8 個字符，並包含大小寫字母、數字和特殊字符。",
        submit: "変更",
        handleBack: "返回",
    },
    SettingsView: {
        h4: "個人資料",
        username: "用戶ID：",
        full_name: "用戶顯示名：",
        mail_address: "電子郵件地址：",
        not_mail_address: "請輸入正確的郵件地址",
        otp_on: "開啓登錄時的郵件2次認證",
        otp_warning: "**注意：如要開啓登錄時的郵件2次認證，需輸入郵件地址",
        modal_title: "郵件地址認證",
        modal_body: "認證碼：",
        otp_msg: "請在下方輸入驗證碼",
        invalid_otp_code_1: "驗證碼不正確！",
        invalid_otp_code_2: "如要開啓登錄時的郵件2次認證，請輸入您的郵件地址",
        setting_ok: "設定OK!",
        send_btn: "驗證",
        close_btn: "取消",
        change_password: "密碼変更",
        update: "更新",
        handle_return: "返回",
        user_plan: "費用賬單查詢",
    },
    UserPlanView: {
        plan_detail: "月間使用狀況",
        element: "項目",
        used: "使用量",
        limit: "使用上限",
        message_count_3: "回答件數(GPT-3.5)",
        message_count_4: "回答件數(GPT-4)",
        resource_detail: "談話資料存儲明細",
        resource_characters_count: "談話資料文字數",
        user_count: "用戶數",
        chat_count: "談話機器人數",
        handle_return: "返回",
    },
    ChatListView: {
        h4: "談話機器人一覽",
        serach_keyword: "關鍵詞：",
        chat_count: "談話機器人数：",
        handle_create: "新建",
        handle_filter: "過濾",
        chat_title: "談話機器人名",
        author: "建立日期",
        operation: "操作",
        delete: "刪除",
        modal_title: "警告",
        warning_msg_1: "請確認",
        warning_msg_2: "即將刪除談話！",
        cancel: "取消",
    },
    ChatView: {
        enter_message: "請輸入信息！",
        file_name: "文件名",
        tags: "標簽",
        source: "【參照來源】：",
    },
    ChatEditResourceView: {
        h4: "編輯資源",
        resource_name: "談話資料名：",
        file_type: "文件形式：",
        tags: "標簽",
        description: "備考：",
        status: "狀態：",
        created_at: "登錄日期：",
        modified_at: "更新日期：",
        resource_text: "文本：",
        submit: "更新",
        backToResources: "返回",
        error_messages: "發生了錯誤",
        close: "關閉",
    },
    LoginView: {
        username: "用戶名：",
        invalid_username_or_password: "用戶名或者密碼不正確！",
        modal_title: "郵件二次認證",
        modal_body: "認證碼：",
        otp_msg: "請輸入郵件中的驗證碼",
        invalid_otp_code_1: "認證碼錯誤！",
        invalid_otp_code_2: "超過最大驗證回數！請重新登錄。",
        send_btn: "驗證",
        close_btn: "取消",
        password: "密碼：",
        submit: "登錄",
        ip_modal_title: "IP白名單錯誤",
        ip_err_1: "客戶端的IP地址不在IP白名單之内。",
        ip_err_2: "請聯係系統管理員。",
    },
    RegisterView: {
        h4: "已登錄談話資料一覽",
        search_keyword: "關鍵詞",
        tags: "標簽",
        total_number_of_characters: "文字數合計：",
        resource_count: "談話資料数：",
        filter: "過濾",
        resource_name: "談話資料名",
        file_type: "文件形式",
        created_at: "登錄日期",
        status: "狀態",
        operation: "操作",
        edit: "編輯",
        delete: "刪除",
    },
    UploadFileView: {
        upload_title: "談話資料登録",
        resource_type: "談話資料類型",
        file: "文件",
        file_upload: "選擇檔案",
        file_name: "文件名",
        tags: "標簽",
        tags_format: "標簽1,標簽2,標簽3",
        uploadFile: "上傳",
        document_title: "頁面標題",
        uploadWebResource: "登録",
    },
    TagsInput: {
        admin_del_msg: "無法刪除管理員使用者權限，因為管理員可以管理所有聊天。",
    },

    UploadServiceView: {
        // upload_title: "談話資料登録",
        resource_type: "談話資料類型",
        file: "文件",
        file_upload: "選擇檔案",
        // file_name: "文件名",
        // tags: "標簽",
        // tags_format: "標簽1,標簽2,標簽3",
        uploadFile: "上傳",
        // document_title: "頁面標題",
        uploadWebResource: "登録",
    },

    SalesListsView: {
        h4: "銷售列表",
        search_keyword: "關鍵字:",
        list_count: "列表數量:",
        handle_create: "新增",
        list_title: "列表標題",
        author: "建立時間",
        edit: "編輯",
        delete: "刪除",

        delete_confirm: "確定要刪除選中的 Sales list ?",
        OK: "確定",
        cancel: "取消"
    },

    SalesListsDetailsLayout: {
        h1: "清單標題 1",
        company_list: "公司列表",
        csv_import: "CSV導入",
        company_setting: "清單設定"
    },

    SalesListsDetailsView: {
        h4: "公司列表",
        search_keyword: "關鍵字:",
        handle_filter: "過慮",
        target_flag: "生成文字",
        target: "生成對象",
        regeneration: "在生成",
        company_information: "獲取公司資訊",
        text_generate: "文章生成",
        text_confirm: "内容確認",
        send_mail: "發電子郵件",
        data_count: "數據總件數：",

        select_all: "全選/取消全選",
        handleCreate: "+ 新增公司",
        handle_send: "大量發送",
        refresh: "更新列表",
        handle_refresh: "刷新內容",

        selected: "選擇",
        company_name: "公司名稱",
        company_url: "URL",

        crawl_status: "公司資訊取得",
        generate_status: "文章生成",
        check_status: "文章確認",
        send_status: "發送電子郵件",

        process_pending: "等待處理",
        processing: "處理中",
        finish: "完成",
        process_fail: "處理失敗",

        generate_pending: "等待生成",
        generating: "生成中",
        generate_fail: "生成失敗",

        uncheck: "未確認",
        checked: "已確認",

        send_none: "未生成",
        send_pending: "等待發送",
        send_processing: "發送中",
        send_finish: "已發送",
        send_fail: "發送失敗",

        operation: "操作",
        edit: "編輯",
        delete: "刪除",

        delete_confirm: "確定要刪除嗎?",
        OK: "確定",
        cancel: "取消",
        send_mail_modal_title: "提示",
        send_mail_modal_text: "郵件已發送",

        model_title: "編輯/確認公司",
        model_company_name: "公司名稱：",
        model_company_url: "URL:",
        model_email: "電子郵件地址：：",
        model_company_detail: "概要：",
        model_mail_text: "文章：",
        model_check_status: "確認：",
        model_update: "更新",
    },

    NewSalesListsDetailsView: {
        h3: "編輯/確認公司",

        company_name: "公司名稱：",
        company_url: "URL:",
        email: "電子郵件地址：",
        company_detail: "概要：",
        mail_text: "文章：",

        return: "返回",
        update: "新增",
    },

    NewSalesListsView: {
        h3: "新增銷售列表",

        resource_type: "談話資料類型",
        file: "文件",

        title_base: "基本資料",
        title_service: "服務資訊",
        title_template: "模板選擇",

        list_name: "銷售列表",
        sender_company_name: "發送公司",
        sender_name: "發送者",
        sender_mail_address: "發送者 email",
        mail_title: "郵件標題",
        to_name: "接收者",
        bcc: "密件副本",
        to_name_default: "負責人",
        file_upload: "*選擇郵件添附檔案：",
        must_pdf: "*僅可上傳PDF檔案",
        not_pdf: "上傳的檔案必須爲PDF",

        service_name: "服務名稱",
        service_detail_summary: "服務詳情",

        template_name: "模板名稱",

        additional_prompt: "提示：",
        check_modal_title: "警告",
        check_modal_close: "關閉",
        check_modal_msg: " 是必填欄位, 請輸入。",

        return: "返回",
        next: "下一步",
        finished: "完成",

        import: "透過文件/URL 輸入"
    },

    EditSalesListsView: {
        h3: "編輯銷售列表",

        resource_type: "談話資料類型",
        file: "文件",

        title_base: "基本資料",
        title_service: "服務資訊",
        title_template: "模板選擇",

        list_name: "銷售列表",
        sender_company_name: "發送公司",
        sender_name: "發送者",
        sender_mail_address: "發送者 email",
        mail_title: "郵件標題",
        to_name: "接收者",
        bcc: "密件副本",

        service_name: "服務名稱",
        service_detail_summary: "服務詳情",

        template_name: "模板名稱",

        additional_prompt: "提示：",
        prompt_str: "【什麼是提示？】<br>" +
            "在此專案中，您可以新增在產生電子郵件文字時要傳達給產生 AI 的限制。<br>" +
            "【具體範例】<br>" +
            "指定字元數``請在600個字元以內建立。<br>" +
            "插入會議預訂連結``請務必在文字後半部包含以下項目。<br>" +
            "「如果您有興趣，請使用下面的連結進行預訂。<br>" +
            "◆ 會議預約連結 https://～～～ *預約約1分鐘內完成。",

        check_modal_title: "警告",
        check_modal_close: "關閉",
        check_modal_msg: " 是必填欄位, 請輸入。",

        return: "返回",
        next: "下一步",
        update: "更新",

        import: "透過文件/URL 輸入"
    },

    SalesListsImportView: {
        h3: "CSV 導入",
        csv_drag: "拖放 CSV 檔案",
        input_directly: "或不使用CSV直接輸入",

        table_name: "欄位對應",

        field_name: "欄位名稱",
        csv_field: "CSV對應欄位",
        sample_value: "取樣值",

        company_name: "公司名稱",
        company_url: "公司網址",
        company_detail: "公司描述",
        email: "郵箱地址",
        tel: "電話",
        contact_url: "連繫網址",
        mail_text: "發送內容",

        upload_file: "導入",
    },

    sales_templates: {
        h4: "電子郵件文字範本列表",
        search_keyword: "關鍵字:",
        list_count: "列表數量:",
        filter: "過濾",
        handle_create: "新增",
        list_title: "模板標題",
        author: "建立時間",
        edit: "編輯",
        delete: "刪除",

        delete_confirm: "確定要刪除選中的 Sales template ?",
        OK: "確定",
        cancel: "取消"
    },

    sales_templates_new: {
        h3: "创建新模板",

        template_name: "模板名稱",
        template_text: "模板內容",

        new: "新增",
        cancel: "取消",

        check_modal_title: "警告",
        check_modal_close: "關閉",
        check_modal_msg: " 是必填欄位, 請輸入。",
    },

    sales_templates_edit: {
        h3: "編輯模板",

        template_name: "模板名稱",
        template_text: "模板內容",

        update: "更新",
        cancel: "取消",

        check_modal_title: "警告",
        check_modal_close: "關閉",
        check_modal_msg: " 是必填欄位, 請輸入。",
    },
    SalesUsageDetailsView: {
        usage_detail: "使用明細",
        item: "項目",
        total_number: "合計件數",
        number_of_success: "成功數",
        number_of_failures: "失敗數",
        number_of_articles_generated: "文章生成數：",
        number_of_emails_sent: "郵件發送數：",
        amount_detail: "費用明細",
        total_amount: "合計金額：",
        detail: "明細：",
        quantity: "數量",
        unit_price: "單價",
        amount: "金額",
        base_amount: "基礎月費",
        base_amount1: "基礎月費(最大",
        base_amount2: "件)",
        append_amount: "追加生成費用",
        ack_button: "返回"
    },

    OptionsErrorView: {
        h1: "無訪問權限",
        error_message: "沒有For Sales的訪問許可權。",
        back_button: "返回"
    }
}
export default tw;